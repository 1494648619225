<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {swalHelper} from "@/helpers/swal-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {paginationHelper} from "@/helpers/pagination-helper";
import selectAllegroAccountModal from "../../../components/allegro/select-allegro-account-modal.vue";

export default {

  components: {
    Layout,
    PageHeader,
    selectAllegroAccountModal
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: 'Allegro',
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "shopId", slot: true, label: this.$t('allegro.table.shop')},
        {key: "name", label: this.$t('allegro.table.account-name')},
        {key: "clientId", label: this.$t('allegro.table.application-id')},
        {
          key: "createdAt", label: this.$t('allegro.table.created-at'), formatter: value => {
            return dateUtil.asDateTime(value)
          }
        },
        {
          key: "tokenExpiresAt", slot: true, label: this.$t('allegro.table.token-expires-at')
        },
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadAccountsList() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/allegro/pagination/${this.getSelectedShopId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            filter: this.table.filter
          }
        });

        this.table.items = data.allegroAccounts
        this.table.totalRows = data.count;
        this.table.rows = data.count;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    async loadShop(shopId) {
      const {data} = await axios.get(`/shop/${shopId}`, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shop = data
    },

/*
    deleteAccount(account) {
      swalHelper.yesOrNoWithSubTitle(() => {
        axios.delete(`/allegro`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {id: account.id}
        }).then(() => {
          toastHelper.success(this.$t('allegro.delete.success'))

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },
*/

    duplicateAccount(account) {
      swalHelper.yesOrNoWithSubTitle(() => {
        const json = {
          allegroAccountToReplaceId: account.id
        }

        axios.post(`/allegro/replace-and-connect`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(({ data }) => {
          window.location = data.redirectUrl;
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },

    isExpires(item) {
      const time = new Date(item.createdAt);
      const currentTime = new Date();
      const diff = currentTime - time;
      return diff >= 5 * 60 * 1000
    },

    isExpiresTime(value) {
      const time = new Date(value);
      const currentTime = new Date();

      return time < currentTime
    },

    openAuctions(allegroAccountId) {
      window.location = `/dashboard/user/allegro/auctions/${allegroAccountId}`
    }

  },

  computed: {
    paginationHelper() {
      return paginationHelper
    },

    dateUtil() {
      return dateUtil
    },

    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  data() {
    return {
      accountsList: null,
      shop: {},

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 200],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,
        submitted: false,
        inputPage: "",

        items: []
      }
    }
  },

  async created() {
    await this.loadShop(this.getSelectedShopId)
  }

}

</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" title="Allegro"/>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div v-if="table.items && table.items.length > 0" class="button-items mb-2">
                  <a class="clickable-element btn btn-success" href="/dashboard/user/allegro/connect">{{ $t('allegro.add-account') }}</a>
                  <a class="clickable-element btn btn-outline-primary" @click="$refs.selectAllegroAccountModal.openModal()">{{ $t('allegro-auctions.title') }}</a>
                </div>
              </div>

              <div v-if="table.items && table.items.length === 0" class="col-12 text-center">
                <p class="text-danger">{{ $t('allegro.no-account') }}</p>
                <div class="button-items">
                  <a class="clickable-element btn btn-success"
                     href="/dashboard/user/allegro/connect">{{ $t('allegro.connect.button') }}</a>
                </div>
              </div>
            </div>

            <div class="py-4">
              <h4 class="card-title mb-4">{{ $t('allegro.list-accounts') }}</h4>

              <ecat-table
                  ref="table"
                  :fields="getFields()"
                  :items="loadAccountsList"
                  :pagination-top="true"
                  :table="table"
              >
                <template v-slot:shopId>
                  {{ shop ? shop.name : '' }}
                </template>

                <template v-slot:tokenExpiresAt="{ item }">
                  <span v-if="item.tokenExpiresAt">{{ dateUtil.asDateTime(item.tokenExpiresAt) }}</span>
                  <span v-else-if="!item.tokenExpiresAt || isExpires(item)" class="badge badge-soft-danger">Błąd Allegro. Prośba o ponowne podpięcie konta.</span>
                  <span v-else></span>
                </template>

                <template v-slot:action="{ item }">
                  <div class="button-items">
<!--                    <b-button :id="`delete-account-${item.id}`" :disabled="true" class="btn-sm font-size-18"
                              variant="danger" @click="deleteAccount(item)">✗
                    </b-button>-->
                    <b-tooltip :target="`delete-account-${item.id}`" placement="left">
                      {{ $t('allegro.tooltip.delete') }}
                    </b-tooltip>

                    <b-button v-if="(!item.tokenExpiresAt || isExpires(item)) || (item.tokenExpiresAt && isExpiresTime(item.tokenExpiresAt))" class="btn-sm" variant="outline-warning" @click="duplicateAccount(item)">Połącz na nowo</b-button>
                  </div>
                </template>
              </ecat-table>
            </div>
          </div>
        </div>
      </div>

      <select-allegro-account-modal ref="selectAllegroAccountModal" @select="openAuctions" />

    </div>
  </Layout>
</template>